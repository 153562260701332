<template>
  <div class="bg-lovin h-100">
    <div class="container h-100">
      <h2 class="text-center"><router-link class="app-title" to="/admin">Ozzie Lovin</router-link></h2>
      <div class="h-100 d-flex justify-content-center align-content-center">
        <div style="margin-top: 4rem">
          <div class="d-flex justify-content-center">
            <router-link to="/edit-links">
              <img src="../assets/imgs/links.png" style="width: 140px" @mouseout="$event.target.src = require('../assets/imgs/links.png')"
                @mouseover="$event.target.src = require('../assets/imgs/hover-links.png')">
            </router-link>
          </div>

          <div class="d-flex justify-content-center" style="margin-top: 10rem">
            <router-link to="/edit-fields">
              <img src="../assets/imgs/fields.png" style="width: 140px"  @mouseout="$event.target.src = require('../assets/imgs/fields.png')"
                   @mouseover="$event.target.src = require('../assets/imgs/hover-fields.png')">
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
